<template>
  <div class="md-layout text-center">
    <div
      class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100"
    >
      <form @submit.prevent="handleSubmit" onSubmit="return false;">
        <login-card header-color="blue">
          <h4 slot="title" class="title">Portal do Usuário</h4>
          <img :src="logo" slot="logo" style="border-radius: 0" />
          <md-field class="md-form-group" slot="inputs">
            <md-icon>verified_user</md-icon>
            <label>Usuário...</label>
            <md-input
              v-model="username"
              name="username"
              required="required"
            ></md-input>
          </md-field>
          <md-field class="md-form-group" slot="inputs">
            <md-icon>lock_outline</md-icon>
            <label>Senha...</label>
            <md-input
              v-model="password"
              type="password"
              required="required"
            ></md-input>
            <p class="text-checkbox" v-if="!loading">
              <input
                type="checkbox"
                v-model="manterConectado"
                class="checkbox"
              />
              <span class="text"> Manter conectado </span>
            </p>
          </md-field>
          <md-button
            slot="footer"
            class="md-simple md-primary md-lg"
            type="submit"
            :disabled="loading"
          >
            Entrar&nbsp;&nbsp;<i class="fas fa-sign-in-alt" />&nbsp;&nbsp;
            <md-progress-spinner
              :md-diameter="15"
              :md-stroke="1"
              md-mode="indeterminate"
              v-show="loading"
            ></md-progress-spinner>
          </md-button>
        </login-card>
        <!-- <p class="text-checkbox" v-if="!loading">
          <input type="checkbox" v-model="manterConectado" class="checkbox" />
          Manter conectado
        </p> -->
      </form>
    </div>
  </div>
</template>
<script>
import LoginCard from "@/components/Cards/LoginCard.vue";
import { router } from "@/helpers";
import { userService } from "@/services";
import { chatOutboxStore } from "@/stores/chat-Outbox.js";
import { mapWritableState, mapActions } from "pinia";
import Swal from "sweetalert2";

export default {
  components: {
    LoginCard,
  },
  data() {
    return {
      username: "",
      password: "",
      loading: false,
      returnUrl: "",
      manterConectado: false,
    };
  },
  props: {
    logo: {
      type: String,
      default: "./img/OutBox_LogoHoriz_White_mini.png",
    },
  },
  created() {
    userService.logout();
    this.returnUrl = this.$route.query.returnUrl || "/";
  },
  methods: {
    handleSubmit(e) {
      const { username, password } = this;
      this.resetStoreChat();
      this.loading = true;
      userService.login(username, password, this.manterConectado).then(
        (tokenResponse) => {
          router.push(this.returnUrl);
        },
        (error) => {
          const textError = error.toString();
          this.loading = false;
          e.preventDefault();
          Swal.fire({
            icon: "error",
            title: "Ops, ocorreu um erro.",
            html: textError,
            buttonsStyling: false,
            customClass: {
              confirmButton: "md-button md-warning",
            },
          });
        }
      );
    },
    ...mapActions(chatOutboxStore, ["resetStoreChat"]),
  },
};
</script>

<style scoped lang="scss">
.checkbox {
  margin: 5px;
  position: relative;
  right: 20px;
  min-width: 15px;
  height: 15px;
}
.text {
  width: 106px;
  position: absolute;
}

.text-checkbox {
  margin: 0;
  width: 1px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  color: #666666;
  right: 252px;
  top: 40px;
  z-index: 10;
}
</style>
